<template>
  <div>
    <Particles
      id='tsparticles'
      :options='particlesOptions'
      style='width: 100%; height: 100%; position: fixed; overflow: hidden; top: 0;'
    />

    <v-row no-gutters align='center' justify='center' :style='{ "height": windowInnerHeight}'>
      <recover-password v-if='isRecoverPassword' @toggle-recover-password='toggleRecoverPassword' />
      <sign-in v-else @toggle-recover-password='toggleRecoverPassword' @signin-in-success='resolveNavigate' />
    </v-row>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { Route } from '@/router';
  import { ACCOUNT_ROLE } from '@/store/modules/Auth';

  export default {
    name: 'Auth',
    components: {
      SignIn: () => import('./SignIn/SignIn.vue'),
      RecoverPassword: () => import('./Recover/RecoverPassword.vue'),
    },
    data: function () {
      return {
        windowInnerHeight: `${window.innerHeight}px`,
        isRecoverPassword: false,
        // PARTICLES OPTIONS
        particlesOptions: {
          background: {
            color: {
              value: this.$vuetify.theme.isDark ? this.$vuetify.theme.themes.dark.primary : this.$vuetify.theme.themes.light.primary,
            },
          },
          fpsLimit: 60,
          interactivity: {
            detectsOn: 'canvas',
            events: {
              onClick: {
                enable: false,
                mode: 'push',
              },
              onHover: {
                enable: true,
                mode: 'repulse',
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 150,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: '#ffffff',
            },
            links: {
              color: '#ffffff',
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: 'none',
              enable: true,
              outMode: 'bounce',
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: 'circle',
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        },
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
    },
    methods: {
      toggleRecoverPassword: function () {
        this.isRecoverPassword = !this.isRecoverPassword;
      },
      resolveNavigate: function () {
        const {
          user,
        } = this.getUser;

        if (user?.account.accountRoleId === ACCOUNT_ROLE.ADMIN || user?.account.accountRoleId === ACCOUNT_ROLE.SUPER_ADMIN) {
          if (this.$router.currentRoute.name !== Route.ENTRY_SEARCH) {
            this.$router.replace({ name: Route.ENTRY_SEARCH });
          }
        } else if (this.$router.currentRoute.name === Route.SIGN_IN || this.$router.currentRoute.name === Route.ACCESS_DENIED) {
          this.$router.replace({ name: Route.HOME });
        }
      },
    },
  };
</script>

<style lang='scss' scoped>
  ::v-deep.v-sheet.v-card {
    border-radius: 0;
  }
</style>
